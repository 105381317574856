import React from 'react';
import { OurInsightsReportViewContext, TemplateProps } from '../../parser';
import { PathService } from '../../services';
import { OurInsightsReportView } from '../../views';

const OurInsightsReport: React.FunctionComponent<TemplateProps<
  OurInsightsReportViewContext
>> = ({ location, pageContext }) => {
  PathService.set(location.pathname);
  return (
    <OurInsightsReportView
      content={JSON.parse(pageContext.content)}
      page={JSON.parse(pageContext.page)}
      relatedPosts={JSON.parse(pageContext.relatedPosts)}
    />
  );
};

export default OurInsightsReport;
